<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline">{{title}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-layout wrap>
              <v-col cols="4" sm="4" md="4">
                <DATA
                  :MyObject="MyObject"
                  Field="data"
                  :Title="$t(`fields.date`)"
                  :RULES="[RULES.required]"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <TIME
                  :MyObject="MyObject"
                  Field="inicio"
                  :Title="$t(`fields.hourstart`)"
                  :RULES="[RULES.required]"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <TIME
                  :MyObject="MyObject"
                  Field="fim"
                  :Title="$t(`fields.hourend`)"
                  :RULES="[RULES.required]"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label class="label-modal">Colaborador</label>
                <v-autocomplete
                  autocomplete="off"
                  :items="getColaboradores"
                  :color="$primary"
                  v-model="MyObject.id_utilizador"
                  :rules="[RULES.required]"
                  item-text="nome"
                  item-value="id_utilizador"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label class="label-modal">Obra</label>
                <v-autocomplete
                  autocomplete="off"
                  :items="getObras"
                  :color="$primary"
                  v-model="MyObject.id_obra"
                  :rules="[RULES.required]"
                  item-text="nome"
                  item-value="id_obra"
                ></v-autocomplete>
              </v-col>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="$primary" text @click="close">
          {{
          $t("actions.cancel")
          }}
        </v-btn>
        <v-btn :color="$primary" text @click="save">
          {{
          $t("actions.confirm")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RULES from "@/mixins/_RULES"
export default {
  components: {
    DATA: () => import("@/components/inputs/Data"),
    TIME: () => import("@/components/inputs/Time")
  },
  computed: {
    title() {
      return typeof this.MyObject.id_agenda == "undefined"
        ? "Novo agendamento"
        : "Alterar agendamento"
    },
    getSession() {
      return this.$store.getters["session/getState"]
    },
    getColaboradores() {
      return this.$store.getters["utilizador/getColaboradores"]
    },
    getObras() {
      return this.$store.getters["obra/getAll"]
    }
  },
  data: () => ({
    dialog: false,
    MyObject: {
      data: "",
      inicio: "",
      fim: "",
      id_obra: "",
      id_utilizador: ""
    },
    Default: {
      data: "",
      inicio: "",
      fim: "",
      id_obra: "",
      id_utilizador: ""
    }
  }),
  mixins: [RULES],
  watch: {
    dialog(val) {
      val || this.close()

      this.$forceUpdate()
    }
  },
  mounted() {},
  created() {
    this.$eventBus.$on("AgendarDialog", (obj, state) => {
      this.dialog = state

      if (obj != null) this.MyObject = { ...obj }
    })
  },
  methods: {
    IsNotAllowed() {
      return this.$store.getters["agenda/getAll"].findIndex(el => {
        return (
          el.id_utilizador == this.MyObject.id_utilizador &&
          new Date(`${this.MyObject.data} ${this.MyObject.inicio}`) >=
            new Date(`${el.data} ${el.inicio}`) &&
          new Date(`${this.MyObject.data} ${this.MyObject.inicio}`) <=
            new Date(`${el.data} ${el.fim}`)
        )
      })
    },
    save() {
      if (!this.$refs.form.validate()) return

      if (this.IsNotAllowed() > -1) {
        this.$toast.error(
          "Este colaborador já tem uma tarefa agendada para este horário!"
        )
        return
      }
      if (typeof this.MyObject.id_agenda == "undefined") {
        this.$store.dispatch("agenda/insert", this.MyObject).then(res => {
          if (res) this.$toast.success(this.$t("messages.success"))
          else this.$toast.error(this.$t("messages.error"))

          this.close()
        })
      } else {
        this.$store
          .dispatch("agenda/update", {
            id: this.MyObject.id_agenda,
            data: this.MyObject.data,
            inicio: this.MyObject.inicio,
            fim: this.MyObject.fim,
            id_utilizador: this.MyObject.id_utilizador,
            id_obra: this.MyObject.id_obra
          })
          .then(res => {
            if (res) this.$toast.success(this.$t("messages.success"))
            else this.$toast.error(this.$t("messages.error"))

            this.close()
          })
      }
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.MyObject = { ...this.Default }
        this.$refs.form.reset()
      }, 110)
    }
  }
}
</script>
<style scoped>
.apagar {
  line-height: 30px !important;
  font-size: 17px !important;
}
</style>
